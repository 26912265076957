


import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { FormControl, Button } from 'react-bootstrap';
import { Header } from './_shared/ModalHeader';
import './_shared/Viewer.css';

const Password = ({ icon, passCheck, close }) => {
    const passes = JSON.parse(process.env.REACT_APP_STAGE_PW);

    const [password, setPassword] = useState('');
    const [error, setError] = useState();

    const onChange = val => setPassword(val);

    const btnClick = event => {
        if (!password) {
            setError(true);
        } else {
            const tempPassword = passes[icon.id].split(' / ');
            let isPwdMatches = false;
                for (let i = 0; i < tempPassword?.length; i++ ) {
                    if (tempPassword[i].toLowerCase() === password?.toLowerCase()) {
                        isPwdMatches = true;
                    }
                }
            // }
            // else {
            //     if (passes[icon.id].toLowerCase() === password?.toLowerCase()) {
            //         isPwdMatches = true;
            //     }
            // }

            if (isPwdMatches) {
                if (icon.id === 'lee') {
                    localStorage.setItem('isLeeFolderUnLocked', isPwdMatches);
                } else if (icon.id === 'bob') {
                    localStorage.setItem('isBobFolderUnLocked', isPwdMatches);
                } else {
                    localStorage.setItem('isDocFolderUnLocked', isPwdMatches);
                }

                passCheck(true);

            } else {
                setError(true);
            }
        }
        event.preventDefault();
    };

    return (
        <Draggable handle='.hak-modal-header-label-secondlevel' bounds={'#folder-bounds'}>
            <div className={'hak-modal-password'}>
                <Header icon={{ label: icon.label }} close={close} secondLevel={true}/>
                <div className='row center'>
                    <form className='hak-form' onSubmit={e => btnClick(e)} data-testid="formSubmit">
                        <span className='center' style={{ 'marginBottom': '10px' }}>
                            <FormControl
                                type='password'
                                name='password'
                                placeholder='Password'
                                defaultValue={password}
                                onChange={event => onChange(event.target.value)}
                                autoFocus
                                data-testid="password"
                            />
                        </span>
                        <div style={{ textAlign: 'center'}}>Password (no spaces)</div>
                        <div style={{ textAlign: 'center'}}>HINT: {icon.lock.hint}</div>

                        <div className='error-message center'>{error && 'Invalid Password'}&nbsp;</div>
                        <span className='center btn-block'>
                            <Button onClick={btnClick} className='btn ok' data-testid="okBtn">OK</Button>
                            <Button className='btn cancel' onClick={close}>CANCEL</Button>
                        </span>
                    </form>
                </div>
            </div>
        </Draggable>
    );
};
export default Password;
