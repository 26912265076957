
import React from 'react';
import { GameStateContext } from './context';

export function GameStateProvider(props) {
    const LOCAL_KEY = 'rip-at-the-rodeo';

    const getState = () => {
        // const val = localStorage.getItem(LOCAL_KEY);
        // if(val && JSON.parse(val)){
        //     return JSON.parse(val);
        // }
        const val = localStorage.getItem(LOCAL_KEY);
        return JSON.parse(val);
    };

    const updateState = state => {
        localStorage.setItem(LOCAL_KEY, JSON.stringify(state));
    };

    const updateStateCtaOnly = cta => {
        const val = localStorage.getItem(LOCAL_KEY);
        // if(val){
            const prevState = JSON.parse(val);
            localStorage.setItem(LOCAL_KEY, JSON.stringify({
                ...prevState,
                cta }));
        // }
    };

    const updateStateChatOnly = chat => {
        const val = localStorage.getItem(LOCAL_KEY);
        // if(val){
            const prevState = JSON.parse(val);
            localStorage.setItem(LOCAL_KEY, JSON.stringify({
                ...prevState,
                chat }));

        // }
    };

    // const setEpisodeEnd = () => {
    //     const val = localStorage.getItem(LOCAL_KEY);
    //     if(val){

    //         const prevState = JSON.parse(val);
    //         localStorage.setItem(LOCAL_KEY, JSON.stringify({
    //             ...prevState,
    //             episodeEnd: true}));

    //     }
    // };

    // const updateStateGoodFencesChatOnly = goodFencesChat => {
    //     const val = localStorage.getItem(LOCAL_KEY);
    //     if(val){

    //         const prevState = JSON.parse(val);
    //         localStorage.setItem(LOCAL_KEY, JSON.stringify({
    //             ...prevState,
    //             goodFencesChat }));

    //     }
    // };

    // const saveAnimalShelterLoginStatus = (status = false) => {
    //     const val = localStorage.getItem(LOCAL_KEY);
    //     if (val) {

    //         const prevState = JSON.parse(val);
    //         localStorage.setItem(LOCAL_KEY, JSON.stringify({
    //             ...prevState,
    //             animalShelterLoggedIn: status }));

    //     }
    // };

    // const saveVuMailLoginStatus = (status = false) => {
    //     const val = localStorage.getItem(LOCAL_KEY);
    //     if (val) {

    //         const prevState = JSON.parse(val);
    //         localStorage.setItem(LOCAL_KEY, JSON.stringify({
    //             ...prevState,
    //             vuMailLoggedIn: status }));

    //     }
    // };

    // const saveEp6GoodGuyUnlock = (status = false) => {
    //     const val = localStorage.getItem(LOCAL_KEY);
    //     if (val) {

    //         const prevState = JSON.parse(val);
    //         localStorage.setItem(LOCAL_KEY, JSON.stringify({
    //             ...prevState,
    //             ep6GoodGuyUnlock: status }));

    //     }
    // };

    return (
        <GameStateContext.Provider
            value={{
                getState: () => getState(),
                updateState: newState => updateState({...newState}),
                // resetState: () => updateState(null),
                // setEpisodeEnd: () => setEpisodeEnd(),
                updateStateCtaOnly: cta => updateStateCtaOnly(cta),
                updateStateChatOnly: chat => updateStateChatOnly(chat)
                // updateStateGoodFencesChatOnly: goodFencesChat => updateStateGoodFencesChatOnly(goodFencesChat),
                // saveVuMailLoginStatus: status => saveVuMailLoginStatus(status),
                // saveAnimalShelterLoginStatus: status => saveAnimalShelterLoginStatus(status),
                // saveEp6GoodGuyUnlock: status => saveEp6GoodGuyUnlock(status)
            }}
        >
            {props.children}
        </GameStateContext.Provider>
    );
}
