
// import react components
import React, { useState} from 'react';
//import draggable
import Draggable from 'react-draggable';
//import header
import { Header } from './_shared/ModalHeader';
//import builder
import Builder from '../builder/Builder';
//import password
import Password from './Password';
//import styles
import './_shared/Viewer.css';

//checks localstorage whether the folder is opened or not and sets the child icons accordingly
const Folder = ({ icon, close, folderOpened }) => {
    let folderUnLocked = false;
    if (icon.id === 'lee') {
        folderUnLocked = localStorage.getItem('isLeeFolderUnLocked') || false;
    } else if (icon.id === 'bob') {
        folderUnLocked = localStorage.getItem('isBobFolderUnLocked') || false;
    } else {
        folderUnLocked = localStorage.getItem('isDocFolderUnLocked') || false;
    }
    const [passed, setPassed] = useState(folderUnLocked);

    // to check when password verified.
    const passCheck = pass => {
        // callback
        folderOpened(icon);
        setPassed(pass);
    };

    return (
        passed
            ? (
                // Draggable component
                <Draggable handle='.hak-modal-header-label' bounds={'#draggable-bounds'}>
                    <div className={'hak-modal'} id={'folder-bounds'}>
                        {/* Header component */}
                        <Header icon={icon} close={close} />
                        {/* Builder component */}
                        {passed && <Builder config={icon.children} />}
                    </div>
                </Draggable>
            ) : (
                // password component
                <Password icon={icon} passCheck={passCheck} close={close} />
            )
    );
};
// export Folder component
export default Folder;
