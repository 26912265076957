
// import react modules
import React, {
  useEffect,
  useState
} from 'react';
//import builder
import Builder from '../builder/Builder';
//import config
import { iconsConfig } from '../../config/Config';
// import function to get a current time
import { getCurrentTimeWithAmPm } from '../../_shared/utils';
//import css file
import './Desktop.css';

// Desktop component
const Desktop = ({ handleLogout }) => {
  // to get current time to show on desktop
  const tempTime = getCurrentTimeWithAmPm();
  // to default time
  const [time, setTime] = useState(tempTime);
  const timeout = 30000;

  // useEffect
  useEffect(() => {
    const timer = setInterval(() => {
      // to get current time
      const tempNewTime = getCurrentTimeWithAmPm();
      // to update current time to state
      setTime(tempNewTime);
    }, timeout);
    // to clear garbage
    return () => clearInterval(timer);
  }, []);

  return (
    // Parent element
    <div
      id='draggable-bounds'
      className={'desktop-container'}
      style={{backgroundImage: `url(https://cdn.huntakiller.com/rip-at-the-rodeo/hawk-springs-rodeo-background.svg)` }}
    >
      {/* Builder component */}
      <Builder config={iconsConfig} />

      {/* Footer  */}
      <div className={'footer'}>
          {/* logout */}
          <div onClick={handleLogout} data-testid='logoutBtn'>LOG OUT</div>
          {/* time */}
          <div data-testid='time'>{time}</div>
      </div>
    </div>
  );
};
// export desktop component
export default Desktop;
