

export const contents = {
  'Tracking' : ['https://cdn.huntakiller.com/rip-at-the-rodeo/tracking.jpg'],
  'Shipment-Invoice' : ['https://cdn.huntakiller.com/rip-at-the-rodeo/shipment-invoice.jpg'],
  'Animal-Meds-Guide': ['https://cdn.huntakiller.com/rip-at-the-rodeo/animal-meds-guide.jpg'],
  'New-Guy-Clay': ['https://cdn.huntakiller.com/rip-at-the-rodeo/newguy-clay.jpg'],
  'Charlie-Just-In-Case': ['https://cdn.huntakiller.com/rip-at-the-rodeo/charlie-just-in-case.jpg'],
  'Bob-Just-In-Case': ['https://cdn.huntakiller.com/rip-at-the-rodeo/bob-just-in-case.jpg'],
  'New-Guy-Clay-2': ['https://cdn.huntakiller.com/rip-at-the-rodeo/newguy-clay-2.jpg'],
  'Notepad': ['https://cdn.huntakiller.com/rip-at-the-rodeo/notepad.jpg']};

export const iconsConfig = [
  {
    id: 'lee',
    icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/lees-locked-folder.svg',
    iconUnLocked: 'https://cdn.huntakiller.com/rip-at-the-rodeo/lees-folder.svg',
    label: 'Lee',
    type: 'folder',
    lock: { type: 'password', hint: 'my post retirement pet', state: 'lee_folder_unlocked' },
    children: [
      {
        id: 'Tracking',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/tracking-icon.svg',
        label: 'Tracking',
        type: 'pdf'},
      {
        id: 'Shipment-Invoice',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/shipment-invoice-icon.svg',
        label: 'Shipment-Invoice',
        type: 'pdf'},
      {
        id: 'Animal-Meds-Guide',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/animal-meds-guide-icon.svg',
        label: 'Animal-Meds-Guide',
        type: 'pdf'}]},
  {
    id: 'bob',
    icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/bobs-locked-folder.svg',
    iconUnLocked: 'https://cdn.huntakiller.com/rip-at-the-rodeo/bobs-folder.svg',
    label: 'Bob',
    type: 'folder',
    lock: { type: 'password', hint: 'my favorite girl + hot sauce', state: 'bob_folder_unlocked' },
    children: [
      {
        id: 'Photo-1',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/photo-1-icon.svg',
        label: 'Photo-1',
        type: 'image',
        url: ['https://cdn.huntakiller.com/rip-at-the-rodeo/photo-1.jpg']},
      {
        id: 'Photo-2',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/photo-2-icon.svg',
        label: 'Photo-2',
        type: 'image',
        url: ['https://cdn.huntakiller.com/rip-at-the-rodeo/photo-2.jpg']},
      {
        id: 'Photo-3',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/photo-3-icon.svg',
        label: 'Photo-3',
        type: 'image',
        url: ['https://cdn.huntakiller.com/rip-at-the-rodeo/photo-3.jpg']},
      {
        id: 'Photo-4',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/photo-4-icon.svg',
        label: 'Photo-4',
        type: 'image',
        url: ['https://cdn.huntakiller.com/rip-at-the-rodeo/photo-4.jpg']},
      {
        id: 'Photo-5',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/photo-5-icon.svg',
        label: 'Photo-5',
        type: 'image',
        url: ['https://cdn.huntakiller.com/rip-at-the-rodeo/photo-5.jpg']},
      {
        id: 'Photo-6',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/photo-6-icon.svg',
        label: 'Photo-6',
        type: 'image',
        url: ['https://cdn.huntakiller.com/rip-at-the-rodeo/photo-6.jpg']}]},
  {
    id: 'doc',
    icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/docs-locked-folder.svg',
    iconUnLocked: 'https://cdn.huntakiller.com/rip-at-the-rodeo/docs-folder.svg',
    label: 'Doc',
    type: 'folder',
    lock: { type: 'password', hint: 'world’s best band + my DayOB', state: 'doc_folder_unlocked' },
    children: [
      {
        id: 'Notepad',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/notepad-icon.svg',
        label: 'Notepad',
        type: 'pdf'},
      {
        id: 'New-Guy-Clay',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/newguy-clay-icon.svg',
        label: 'New-Guy-Clay',
        type: 'pdf'},
      {
        id: 'Charlie-Just-In-Case',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/charlie-just-in-case-icon.svg',
        label: 'Charlie-Just-In-Case',
        type: 'pdf'},
      {
        id: 'Bob-Just-In-Case',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/bob-just-in-case-icon.svg',
        label: 'Training Manual',
        type: 'pdf'},
      {
        id: 'New-Guy-Clay-2',
        icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/newguy-clay-2-icon.svg',
        label: 'New-Guy-Clay-2',
        type: 'pdf'}]},
  {
    id: 'july calendar page',
    icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/july-calendar-page-icon.svg',
    label: 'July Calendar Page',
    type: 'image',
    url: ['https://cdn.huntakiller.com/rip-at-the-rodeo/july-calendar-page.jpg'],
    position:'desktop'},
  {
    id: 'contact',
    icon: 'https://cdn.huntakiller.com/rip-at-the-rodeo/email-contact-desktop.svg',
    label: 'Contact',
    type: 'contact'}];

export const mailConfig = {
  toFrom: 'ROSARIO GARCIA',
  subject: 'Murderer'};

export const mailAttachments = {
  '0' : [{ type: 'image', label: 'Rosario Garcia', url: 'https://cdn.huntakiller.com/rip-at-the-rodeo/rosario-letter.jpg' }]};

const _One = 'Charlie, Chuck, Chuckwagon, Chuckwagon Charlie, Chuckwagon Charlie Collins, Charlie Chuckwagon, Chef Clown, ';
const _Two = 'Chuckwagon the Clown, Charlie Collins, Chuckwagon Charlie the Clown, Charlie Chuckwagon Collins, Charlie “Chuckwagon” Collins, The Chef Clown, The Other Clown';

export const mailInputs = {
  '0': _One + _Two,
  '1': 'Rosario Garcia, Rosario, Patches’ ex-wife, Ms. Garcia, Garcia',
  '2': 'The Bull, Bull',
  '3': 'Lee Goggins, Lee, Kangaroo Jack, Goggins',
  '4': 'Sally, Sally Hearst, Mrs. Hearst, Queenie, Rodeo Queen',
  '5': 'Bob, Bob Hearst, Sally’s Husband, The Judge, The Head Judge',
  '6': 'Clay, Patches’ Son, Clay Carson',
  '7': 'Doc, Doc Burton, Dr. Doc Gareth Burton, Doc Gareth Burton, Gareth Burton, Dr. Burton, The Doctor, The Vet',
  '8': 'Any other answer...'};

export const mailOutputs = {
  '0': `{{Correct}}Charlie!!! You're right! It has to be him!!`,
  '1': '{{Incorrect}}I’m shocked you’d think I’d ever hurt Patches. No, I’m not the killer. Look closer at the clues and try again.',
  '2': '{{Incorrect}}The bull may have gored Patches to death, but it was just being a bull and Patches should have been able to handle it. A person was involved. Who was it?',
  '3': '{{Incorrect}}I don’t think it was Lee. Try looking again at the clues and let me know who you think is the most guilty.',
  '4': '{{Incorrect}}I don’t think Queenie did it. Is there anything you could take a closer look at? Let me know who you think is most likely guilty.',
  '5': '{{Incorrect}}That can’t be right. Look over everything again and see who besides Bob seems most guilty.',
  '6': '{{Incorrect}}I really don’t think it could have been Patches’ own son. Check the clues again and let me know who’s more likely the killer.',
  '7': '{{Incorrect}}I don’t think it was Doc. Try looking at the clues and let me know who you think is the most guilty.',
  '8': '{{Incorrect}}No, that doesn’t help. I need you to look closer at the clues, but it has to be one of our main suspects.'};

  export const download = async (url, name, e) => {
    e.preventDefault();
    await fetch(url+'?crossorigin')
      .then(response => response.blob())
      .then(blob => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';
        a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch(() => this.setState({error:true}));
  };
