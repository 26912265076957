

//import styles
import './Viewer.css';

export const Header = ({vumail, icon, close, secondLevel}) => {
    const classLabel = `${secondLevel ? 'hak-modal-header-label-secondlevel' : 'hak-modal-header-label' }`;
    return (
        <div className={vumail ? 'hak-modal-header-vumail' : 'hak-modal-header'}>
            <div className='header-block'>
                <div
                    style={{
                        width: '100%',
                        textAlign:'center',
                        fontWeight: 'Bold'}}
                    className={classLabel}
                >
                    {icon.label}
                </div>
                {close &&
                    <button
                        type='button'
                        style={{
                            width: '5vh',
                            border: '0px transparent',
                            backgroundColor: 'transparent',
                            marginRight: '5px',
                            marginBottom:'4px'}}
                        onClick={close}
                        data-testid='attach-img-close-btn'
                    >
                        <img
                            src='https://cdn.huntakiller.com/rip-at-the-rodeo/mediaplayer-close-button.svg'
                            alt='Close'
                        />
                    </button>
                }
            </div>
        </div >);
};
