
// import styles
import './Viewer.css';

// Header component
export const Header = ({ icon, close, secondLevel }) => {
    // to set className
    const showClassName = obj => {
        let tempVal;
        if (obj) {
            tempVal = 'hak-modal-header-label-secondlevel';
        } else {
            tempVal = 'hak-modal-header-label';
        }
        // return value
        return tempVal;
    }
    return (
        // header block
        <div className={'hak-modal-header'}>
            <div
                // custom styles
                style={{
                    width: '97%',
                    float: 'left',
                    textAlign: 'center',
                    fontWeight: '600'}}
                className={showClassName(secondLevel)}
            >
                {icon.label}
            </div>

            {close &&
                // close button
                <button
                    type='button'
                    onClick={close}
                    // custom styles
                    style={{
                        width: '5vh',
                        border: '0px transparent',
                        backgroundColor: 'transparent',
                        marginRight: '5px',
                        marginBottom:'4px'}}
                    data-testid="icon-close"
                >
                    {/* close icon */}
                    <img src='https://cdn.huntakiller.com/rip-at-the-rodeo/mediaplayer-close-button.svg' alt='Close' />
                </button>
            }
        </div>
    );
};
