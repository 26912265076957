

export const views = {
    //menu
    MENU: 'menu',
    //new
    NEW: 'new',
    // inbox
    INBOX: 'inbox',
    //sent
    SENT: 'sent',
    //trash
    TRASH: 'trash',
    //mail
    MAIL: 'mail'};

