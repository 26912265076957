
// import react components
import React, { useState } from 'react';
// import draggable npm
import Draggable from 'react-draggable';
// import header
import { Header } from './_shared/ModalHeader';
// import contents
import { contents, download } from '../../config/Config';
// import styles
import './_shared/Viewer.css';
import './Viewer.css'

// PDFViewer component
const PDFViewer = ({ icon, close }) => {
  const btnClassName = 'btn btn-sm';
  const width = 100;
  const maxWidth = 25;
  const minWidth = 350;

  const [current] = useState(0);
  const imageLoaded = () => setLoading(false);
  const [loading, setLoading] = useState(true);
  const [imgWidth, setImgWidth] = useState(width);

  // to zoom in when user click on zoom in button
  const zoom = zoomIn => {
    let widthPercent = imgWidth;
    const range = 20;
    let tempWidthPer;
    if (zoomIn) {
      tempWidthPer = Math.max(widthPercent - range, maxWidth);
    } else {
      tempWidthPer = Math.min(widthPercent + range, minWidth);
    }
    widthPercent = tempWidthPer;
    setImgWidth(widthPercent);
  };

  // to show or hide
  const displayNoneBlock = objVal => {
    let tempVal = '';
    if (objVal) {
      tempVal = 'none';
    } else {
      tempVal = 'block';
    }
    return tempVal;
  };

  return (
    // Draggle block
    <Draggable
      handle='.hak-modal-header-label-secondlevel'
      bounds={'#folder-bounds'}
    >
      <div className={'hak-modal-pdf'}>
        {/* Header */}
        <Header icon={icon} close={close} secondLevel={Boolean(icon.child)} />
        {/* Loader */}
        {loading && <div className={'center'}>Loading..</div>}

        {/* to show the pdf image */}
        <div style={{ 'overflow': 'auto', 'maxHeight': '70vh' }}>
          <img
            className='hak-content'
            style={{ width: imgWidth + '%', display: displayNoneBlock(loading) }}
            onLoad={imageLoaded}
            src={contents[icon.id][current]}
            alt={icon.id}
            data-testid='pdf-img-load-id'
          />
        </div>

        {/* // Footer block */}
        <div
          className={'center  hak-image-row hak-zoom-icon hak-pdf-footer'}
          style={{ 'maxHeight': '5vh' ,justifyContent:'center'}}
        >
          <div className='d-flex justify-content-between p-1'>
              <div style={{borderBottom: '0px'}}>
                {/* Zoom In */}
                <button
                  disabled={imgWidth >= minWidth}
                  className={btnClassName}
                  onClick={() => zoom(false)}
                >
                  <img
                    style={{ width: '20px' }}
                    src='https://cdn.huntakiller.com/rip-at-the-rodeo/zoomin-icon.svg'
                    alt='Zoom in'
                    data-testid='pdf-img-zoom-in'
                   />
                </button>
                {/* Zoom out */}
                <button
                  disabled={imgWidth <= maxWidth}
                  className={btnClassName}
                  onClick={() => zoom(true)}
                >
                  <img
                    style={{ width: '20px' }}
                    src='https://cdn.huntakiller.com/rip-at-the-rodeo/zoomout-icon.svg'
                    alt='Zoom out'
                    data-testid='pdf-img-zoom-out'
                  />
                </button>

                {/* Download */}
                <button
                  className={btnClassName}
                  onClick={e => download(contents[icon.id][current], icon.label, e)}
                >
                  <img
                    style={{ width: '17px' }}
                    src='https://cdn.huntakiller.com/rip-at-the-rodeo/download-icon.svg'
                    alt='download'
                    data-testid='pdf-img-download'
                  />
                </button>
              </div>
            </div>
          </div>
      </div>
    </Draggable>
  );
};

export default PDFViewer;
