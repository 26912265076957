
// import react components
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
//import getComponentTag
import { getComponentTag } from '../../_shared/utils';
//import builder file styles
import './Builder.css';

// Builder module
const Builder = forwardRef(({ config }, ref) => {
    // to set current file
    const [current, setCurrent] = useState(null);
    // to reload
    const [reload, setReload] = useState(false);

    // to close dialogue, using useImperativeHandle
    useImperativeHandle(ref, () => ({
        closeWindows() {
            setCurrent(null);
        }}));

    //when cross icon is clicked
    const close = () => {
        setCurrent(null);
    };

    // to open when an icon is clicked
    const iconClick = icon => {
        setCurrent(icon);
    };

    // to open folder
    const folderOpened = icon => {
        if (icon) {
            // to reload
            setReload(true);
            // to set current icon state
            setCurrent(icon);
        }
    };

    // useEffect to reload
    useEffect(() => {
        if (reload) {
            setReload(false);
        }
    }, [reload]);

    //get item from local storage to check whether the folder is opened state
    //if it is true , it returns the icon of an open folder
    // else returns the icon if closed folder

    const setIcon = icon => {
        // temp variable to assign locked status
        let isFolderUnLocked ;
        // for lee
        if (icon.id === 'lee') {
            isFolderUnLocked = localStorage.getItem('isLeeFolderUnLocked') || false;
        // for bob
        } else if (icon.id === 'bob') {
            isFolderUnLocked = localStorage.getItem('isBobFolderUnLocked') || false;
        // for doc
        }  else if (icon.id === 'doc') {
            isFolderUnLocked = localStorage.getItem('isDocFolderUnLocked') || false;
        } else {
            // other
            isFolderUnLocked=false;
        }

        // to lock or unlocked
        const showLockedUnLocked = obj => {
            let tempVal;
            if (obj) {
                tempVal = icon.iconUnLocked;
            } else {
                tempVal = icon.icon;
            }
            // to return actual folder status.
            return tempVal;
        };

        return (
            <li key={icon.id} className={`mb-4  ${icon.type}  customize-icon`}>
                {/* to display image */}
                <div className='desktop-icon' alt={icon.id}>
                    <img
                        src={showLockedUnLocked(isFolderUnLocked)}
                        onClick={() => iconClick(icon)}
                        alt={icon.label}
                        data-testid={`folder-${icon.id}`}
                    />
                </div>
            </li>
        );
    };

    return (
        <>
            {/* ul bock */}
            <ul className='list-unstyled list desktop-icons-container'>
                {/* to show image icon based on type */}
                {config.map(icon => (
                    setIcon(icon)
                ))}
                <li className='expand-block'>
                    {/* to open dialogue based on user actions */}
                    {current?.type &&
                        // child block
                        <div className='child-block'>
                            {/* to show the component type whether folder, image or pdf, etc., */}
                            {getComponentTag(current, close, folderOpened)}
                        </div>
                    }
                </li>
            </ul>
        </>
    );
});
// to export builder file
export default Builder;
