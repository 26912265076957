
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { download } from '../../config/Config';
import { Header } from './ModalHeader';
import './Viewer.css';

const ImageViewer = ({ vumail, icon, close, openContact, parent }) => {
  const [loading, setLoading] = useState(true);
  const imageLoaded = () => setLoading(false);
  const nodeRef = React.useRef(null);
  const imgRef = React.useRef(null);

  const [current, setCurrent] = useState(0);
  const btnClassName = 'btn btn-sm';

  const backClick = () => {
    current > 0 && setCurrent(current - 1);
    const imgContainer = imgRef?.current;
    imgContainer.scrollTop = 0;
  };

  const fwClick = () => {
    current < icon.url.length - 1 && setCurrent(current + 1);
    const imgContainer = imgRef?.current;
    imgContainer.scrollTop = 0;
  };

  const width=100;
  const maxWidth=25;
  const minWidth=350;
  const [imgWidth, setImgWidth] = useState(width);

  const zoom = zoomIn => {
    let widthPercent = imgWidth;
    const range = 20;
    widthPercent = zoomIn ? Math.max(widthPercent - range, maxWidth) : Math.min(widthPercent + range, minWidth);
    setImgWidth(widthPercent);
  };

  return (
    <Draggable
      nodeRef={nodeRef}
      handle={icon.child ? '.hak-modal-header-label-secondlevel' : '.hak-modal-header-label'}
      bounds={icon.position !== 'desktop' ? '#folder-bounds' : '#draggable-bounds'}
    >
      <div
        ref={nodeRef}
        className={icon.position === 'desktop' ? 'hak-image-box':'hak-image-box hak-modal'}
        id={'folder-bounds'}
      >
        <div className='hak-image-row header'>
          <Header vumail={vumail} icon={icon} close={close} parent={parent} secondLevel={Boolean(icon.child)} />
        </div>

        <div
          ref={imgRef}
          className='hak-image-row content'
          style={{ height: icon?.vh ? icon.vh + 'vh' : '84vh' }}
        >
          <img
            className='hak-content'
            style={{ width: imgWidth + '%', display: loading ? 'none' : 'block' }}
            onLoad={imageLoaded}
            src={icon.url[current]}
            alt={icon.id}
            data-testid='img-load-id'
          />
        </div>

        <div className='hak-image-row footer hak-image-row-footer'>
          <div className='d-flex justify-content-center w-100 p-1 hak-zoom-icon'>
            {icon.url.length > 1 &&
              <div style={{borderBottom: '0px'}}>
                <button className={'btn btn-sm btn-dark'} onClick={backClick} data-testid='img-bk-click'>{' < '}</button>
                <span className={'p-2 mt-3'}>{'page: '}{current + 1}{'/'}{icon.url.length}</span>
                <button className={'btn btn-sm btn-dark'} onClick={fwClick} data-testid='img-fw-click'>{' > '}</button>
              </div>
            }

            <div style={{borderBottom: '0px'}}>
              {/* Zoom In */}
              <button
                className={btnClassName}
                onClick={() => zoom(false)}
                disabled={imgWidth >= minWidth}
              >
                <img
                  src='https://cdn.huntakiller.com/rip-at-the-rodeo/zoomin-icon.svg'
                  style={{ width: '20px' }}
                  alt='Zoom in'
                  data-testid='img-zoom-in'
                />
              </button>
              {/* Zoom Out */}
              <button
                className={btnClassName}
                onClick={() => zoom(true)}
                disabled={imgWidth <= maxWidth}
                >
                <img
                  src='https://cdn.huntakiller.com/rip-at-the-rodeo/zoomout-icon.svg'
                  style={{ width: '20px' }}
                  alt='Zoom out'
                  data-testid='img-zoom-out'
                />
              </button>
              {/* Download */}
              <button
                className={btnClassName}
                onClick={e => download(icon.url[current], icon.label, e)}
                data-testid='img-download'
              >
                <img style={{ width: '17px' }} src='https://cdn.huntakiller.com/rip-at-the-rodeo/download-icon.svg' alt='Download' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};
export default ImageViewer;
