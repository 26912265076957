

import { useState, useEffect } from 'react';
//import folder component
import Folder from '../components/viewer/Folder';
// import pdf viewer component
import PDFViewer from '../components/viewer/PdfViewer';
//import contact component
import Contact from '../components/contact/Contact';
//import image component
import ImageView from '../components/viewer/ImageViewer';
//import mail inputs and output

import { mailInputs, mailOutputs } from '../config/Config';

//qna maker
export const qna = question => {
    let tempResponse = '';
    let isCorrectAnswer = false;
    for (const objKey in mailInputs) {
        if (mailInputs.hasOwnProperty(objKey)) {
            const tempObjKeyValue = mailInputs[objKey].split(', ');
            tempObjKeyValue.forEach(element => {
                if (element.toLowerCase() === question.toLowerCase()) {
                    tempResponse = mailOutputs[String(objKey)];
                    isCorrectAnswer = true;
                }
            });
        }
    }
    if (!isCorrectAnswer) {
        tempResponse = mailOutputs['8'];
    }
    return tempResponse;
};

//media query
export const useMediaQuery = query => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
      const media = window.matchMedia(query);
        if (media?.matches !== matches) {
            setMatches(media?.matches);
        }
    },[matches, query]);
    return matches;
};

//date
//converts date to iso date and local time
export const prettyDate =()=> {
    const today = new Date();
    const dateLimit = 10;
    const date = today.toISOString().slice(0, dateLimit);
    const time = today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    // return date and time
    return `${date} ${time}`;
};

//component tag
//icon type is mapped and passed to the respective component
export const getComponentTag = (icon, close, folderOpened) => {
    const components = {
        folder: Folder,
        pdf : PDFViewer,
        contact: Contact,
        image: ImageView };

    // to call appropriate component.
    const TagName = components[icon.type];
    return <TagName icon={icon} close={close} folderOpened={folderOpened} />;
};

// to get current time and update on desktop
export const getCurrentTimeWithAmPm = () => {
    // get date
    const tempDate = new Date();
    // get hours
    const tempTime = tempDate.getHours();
    // get minutes
    const tempMinutes = tempDate.getMinutes();

    // to set 12 hours instead 24 hrs
    const hr = 12;
    // hours
    let hours;
    // to set am or pm
    let amPm;
    //condition to check if hours greater than 12 or not
    if (tempTime > hr) {
        hours = tempTime - hr;
        amPm = 'PM';
    } else {
        hours = tempTime;
        amPm = 'AM';
    }

    // temp variable for minutes
    let tempMin;
    if (String(tempMinutes).length === 1) {
        // to have 0 as prefix for minutes when < 10
        tempMin = `0 ${tempMinutes}`;
    } else {
        // to show minutes >= 10
        tempMin = tempMinutes;
    }
    // to return current time with am pm.
    return `${hours}:${tempMin} ${amPm}`;
};
