

import { useState, useContext, useEffect } from 'react';
//import bootstrap components
import { Spinner,Row, Col } from 'react-bootstrap';
//import views
import { views } from './Views';
// import header
import Header from './Header';
//import left menu
import LeftNav from './LeftNav';
// import mail list
import MailsList from './MailsList';
// import view mail
import ViewMail from './ViewMail';
// import new mail
import NewMail from './NewMail';
//import qna, usemediaquery, prettydate
// import qna from config
import { qna, useMediaQuery, prettyDate} from '../../_shared/utils';
// import game state context
import { GameStateContext } from '../../context/context';
// import mail configs
import {
    mailAttachments,
    mailConfig
} from '../../config/Config';
//import contact styles
import './Contact.css';

// Contact module
const Contact = ({ icon, close }) => {
    // to use media query.
    const small = useMediaQuery('(max-width: 1024px)');
    // to get context
    const context = useContext(GameStateContext);
    // to set view height as 33vh
    const viewHeight = '32vh';
    // to set body as empty.
    const toBody = '';
    const vh = 74;
    // to set default view as inbox
    const defaultView = views.INBOX;
    // to set user email as you.
    const currentUserEmail = 'You';

    //getting context
    const { chat } = context?.getState();
    // to assign from addess
    const recipient = mailConfig.toFrom;
    // to assign mail subject
    const subject = mailConfig.subject;

    const [activeView, setActiveView] = useState(defaultView);
    const [activeMail, setActiveMail] = useState();
    const [showMenu, setShowMenu] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [mails, setEmailsList] = useState(chat);

    //toggle menu that appears on  mobile
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    //shows the mail list
    const showMail = mail => {
        setActiveView(views.MAIL);
        setActiveMail(mail);
        setShowMenu(false);
    };

    //appends the new mail that user sends
    const setMails = mail => {
        setEmailsList(mail);
        context.updateStateChatOnly(mail);
    };

    //marks whether the mail is viewed or not
    const setViewed = id => {
        const mailsCopy = [...mails];
        const markViewed = mailsCopy.find(m => m.id === id);
        markViewed.viewed = true;
        setMails([...mailsCopy.filter(m => m.id !== id), { ...markViewed }]);
    };

    //send a new mail
    const sendMail = async mail => {
        // to compose mail content
        const incoming = {
            type: views.INBOX,
            sender: recipient,
            to: currentUserEmail,
            dateTime: prettyDate(),
            viewed: false,
            subject};

        // to assign mail body
        const playerResponse = mail.body;
        const response = qna(playerResponse);
        const chats = response;
        const isCorrect = chats.indexOf('{{Correct}}') > -1;
        if (isCorrect) {
            incoming.attachments = mailAttachments['0'];
            incoming.body=chats.replace('{{Correct}}', '').replace('{{Incorrect}}', '');
            // if(cta === 1) {
                //2 is max cta
                const MAX_CTA = 2;
                context.updateStateCtaOnly(MAX_CTA);
            // }
        } else {
            // to set body content
            incoming.body = chats.replace('{{Correct}}', '').replace('{{Incorrect}}', '');
        }

        // to set spinner state
        setShowSpinner(true);

        const counter = 1000;
        // add item to sent items list
        setMails([...mails, { ...mail, sender: currentUserEmail, id: Date.now() }, {...incoming, id: Date.now() + counter}]);

        const timeout = 750;
        setTimeout(()=>{
            setShowSpinner(false);
            setActiveView(views.INBOX);
        }, timeout);
    };

    //goes back to view if the cross is clicked
    const back = view => {
        setActiveView(view);
    };

    //navigation that appears on desktop
    const leftNavSelect = val => {
        setActiveView(val);
        setShowMenu(false);
    };

    // open new mail for mobile view only
    useEffect(() => {
        if(small){
            // to show left menu bar
            leftNavSelect(views.NEW);
        }
    },[small]);

    //moves mail to trash
    const trashEmail = mail => {
        setShowSpinner(true);

        const mailsCopy = [...mails];

        const toTrash = mailsCopy.find(m => m.id === mail.id);
        toTrash.type = views.TRASH;

        setMails([...mailsCopy.filter(m => m.id !== mail.id), { ...toTrash }]);

        setActiveView(views.TRASH);
        const timeout =500;

        setTimeout(()=>{
            setShowSpinner(false);
        }, timeout);
    };

    return (
        // Container block
        <div className='contactContainer'>
            {/* // Header block */}
            <Header close={close} toggleNav={toggleMenu} />
            {/* Content block */}
            <Row className='contact-body' style={{ backgroundColor: 'white', margin: '0px' }}>
                {((small && showMenu) || !small) &&
                    <Col sm={12} md={3} lg={2} >
                        {/* to show left menu */}
                        <LeftNav activeView={activeView} setActive={leftNavSelect} />
                    </Col>
                }
                {showSpinner &&
                    // to show spinner
                    <Col className={'spinnerContainer'} sm={12} md={9} lg={10} style={{ borderLeft: '1px dotted lightgray' }} >
                        <Spinner className={'spinnerInside'} variant='secondary' animation='border' />
                    </Col>
                }
                {!showSpinner &&
                    <Col sm={12} md={9} lg={10} style={{ borderLeft: '1px dotted lightgray' }}>
                        {((small && !showMenu) || !small) &&
                            <div>
                                {/* Inbox */}
                                {activeView === views.INBOX &&
                                    <MailsList type={views.INBOX} mails={mails.filter(m => m.type === views.INBOX)} setActiveMail={showMail} />
                                }
                                {/* Sent */}
                                {activeView === views.SENT &&
                                    <MailsList type={views.SENT} mails={mails.filter(m => m.type === views.SENT)} setActiveMail={showMail} />
                                }
                                {/* Trash */}
                                {activeView === views.TRASH &&
                                    <MailsList type={views.TRASH} mails={mails.filter(m => m.type === views.TRASH)} setActiveMail={showMail} />
                                }
                                {/* New mail */}
                                {activeView === views.NEW &&
                                    <NewMail sendMail={sendMail} back={back} recepient={recipient} sender={currentUserEmail} toBody={toBody} subject={subject} />
                                }
                                {/* View mail */}
                                {activeView === views.MAIL &&
                                    <ViewMail vh={vh} mail={activeMail} back={back} trash={trashEmail} height={viewHeight} setViewed={setViewed} />
                                }
                            </div>
                        }
                    </Col>
                }
            </Row>
        </div>
    );
};
// export contact module
export default Contact;
